/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import { useTranslation } from "react-i18next";
import legalStyles from "../../sections/LegalHero/index.module.css";
import LegalDocument from "../legal-document";

function PrivacyPolicy({ launcher }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("es-ES");
  }, []);

  return (
    <LegalDocument
      title={t("pages.privacy.label")}
      bodyTitle={t("pages.privacy.title")}
      currentPage="privacy"
      launcher={launcher}
    >
      <Typography variant="h2" color="yellow" weight="medium">
        Sioslife – For Younger Spirits (Sioslife S.A.)
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Esta Política de Privacidad y Protección de Datos Personales se aplica
          de manera general a la recopilación y procesamiento de los datos
          personales proporcionados por el Usuario que accede a cualquier
          plataforma de Sioslife, es decir, a aquellos datos recopilados a
          través de formularios, documentos u otros medios destinados a permitir
          el contacto con Sioslife S.A., persona jurídica con NIF 513107517,
          inscrita en el Registro Mercantil de Braga (Portugal), con el mismo
          número, y con sede en la Rua Eng. Nuno Álvares Pereira, nº 33, 4710
          915 Braga (Portugal), y con un capital social de 61.745,71 EUR, en lo
          sucesivo denominada &quot;Sioslife&quot;, aplicándose aún al ejercicio
          de derechos, por el Usuario, en relación a estos datos, en los
          términos de la legislación vigente aplicable.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Esta Política es de carácter general, por lo que la información
          proporcionada en ella puede ser complementada o eliminada, total o
          parcialmente, por otras políticas, contratos, avisos o información más
          específica que pueda haber estado o esté disponible en el ámbito de
          ciertos tipos de procesamiento de datos personales, o de relación
          comercial entre Sioslife y el Usuario.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        1. Introducción
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          ¡Gracias por utilizar los sistemas Sioslife!
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          En Sioslife, queremos darle la mejor experiencia de uso posible a
          nuestros servicios. Y precisamente por eso, necesitamos conocer sus
          hábitos de uso en nuestros sistemas, lo que permitirá personalizar y
          mejorar progresivamente su experiencia con nosotros. En este sentido,
          queremos explicar de una forma transparente por qué y cómo
          recopilamos, compartimos, almacenamos y usamos sus datos personales,
          informándole al mismo tiempo de las formas de control y las
          posibilidades de elección a su alcance en lo que respecta al momento y
          la forma en que elige compartir sus datos personales.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          En la presente Política de Privacidad, o resumidamente,
          &quot;Política&quot;, explicaremos con detalle toda la información a
          tener en cuenta para la gestión de sus datos personales.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        2. Sobre este documento
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          La presente &quot;Política&quot; define los detalles esenciales sobre
          su relación de datos personales con Sioslife. La Política se aplica a
          todos los servicios Sioslife y cualquier servicio asociado a su uso
          (&quot;Servicios Sioslife&quot;). Las condiciones que rigen el uso de
          los Servicios Sioslife se encuentran definidos en nuestro documento
          Términos y Condiciones de Uso (los &quot;Términos y Condiciones de
          Uso&quot;).
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Siempre que sean desarrollados, ofrecidos o puestos a disposición
          nuevos servicios o productos adicionales, en los casos en que la
          introducción de los mismos dé lugar a cualquier alteración material en
          la forma como recopilamos o procesamos los datos personales, nos
          comprometemos a ofrecer todas las nuevas informaciones, condiciones
          y/o políticas adicionales. En los casos en que esto no sea necesario,
          y salvo indicación en contra, los nuevos servicios o productos se
          encontrarán sujetos a la presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Así, la presente Política tiene como objetivos:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ol>
          <li>
            <Typography color="black">
              1. Garantizar su comprensión sobre los datos personales que
              recopilamos, las razones para esta recopilación y su utilización,
              y con quién los compartimos;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              2. Explicar la forma como utilizamos los datos personales que
              comparte con nosotros, en concreto para garantizar una experiencia
              de utilización cada vez mejor;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              3. Explicarle sus derechos y opciones en relación a los datos
              personales que recopilamos y procesamos, y la forma en que
              esperamos proteger su privacidad.
            </Typography>
          </li>
        </ol>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Esperamos que este documento sea capaz de clarificar todas las
          cuestiones relacionadas con la utilización de datos personales en los
          servicios Sioslife. Para obtener más información o simplemente
          clarificar otras cuestiones, sepa cómo puede entrar en contacto con
          nosotros en la Sección 13 &quot;¿Cómo puede entrar en contacto con
          nosotros?&quot;. Como alternativa, en el caso de que no concuerde con
          la presente Política, recuerde que podrá optar por no usar los
          Servicios Sioslife.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        3. Posibilidades de elección y formas de control
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          El Reglamento General sobre la Protección de Datos o &quot;RGPD&quot;
          ofrece determinados derechos a las personas físicas en lo que se
          refiere a sus datos personales. Los derechos otorgados a las personas
          físicas, sin perjuicio de las limitaciones previstas en la legislación
          aplicable, son los siguientes:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              Derecho de acceso - derecho a ser informado y a solicitar acceso a
              sus datos personales que procesamos;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Derecho de rectificación - derecho a solicitar que cambiemos o
              actualicemos sus datos personales cuando sean incorrectos o estén
              incompletos;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Derecho de eliminación - derecho a solicitar que eliminemos sus
              datos personales de nuestros servicios y sistemas;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Derecho de limitación - derecho a solicitarnos que cesemos,
              temporal o permanentemente, el procesamiento de todos o algunos de
              sus datos personales;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Derecho de oposición - derecho, en cualquier momento, a oponerse
              al tratamiento de sus datos personales por motivos relacionados
              con la situación particular; o derecho a oponerse al procesamiento
              de sus datos personales con fines de marketing directo;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Derecho a la portabilidad de datos - derecho a solicitar una copia
              de sus datos personales en formato electrónico y derecho a
              transmitirlos con el fin de utilizarlos al servicio de terceros;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Derecho a no ser objeto de una toma de decisiones automatizada -
              derecho a no ser objeto de una decisión basada únicamente en la
              toma de decisiones automatizada, incluso con respecto a la
              elaboración de perfiles, si la decisión tiene un efecto legal o un
              efecto igualmente significativo sobre el usuario;
            </Typography>
          </li>
        </ul>
        <div className={legalStyles.description}>
          <Typography color="black">
            Si tiene alguna duda sobre su privacidad, sus derechos o el
            ejercicio de los mismos, póngase en contacto con nuestro Delegado de
            Protección de Datos Personales mediante el formulario
            &quot;Contáctenos&quot; de nuestro sitio web. En caso de que tenga
            dudas sobre nuestro método de tratamiento de sus datos personales,
            nos gustaría que continuara trabajando con nosotros para aclararlas.
            Sin embargo, también puede contactar y ejercer su derecho a
            presentar una queja ante su Autoridad de Protección de Datos local.
          </Typography>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        4. ¿Qué datos personales recaudamos?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          En las tablas a continuación, enumeramos las categorías de datos
          personales que recopilamos y usamos, en relación a los usuarios y cómo
          los recopilamos:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          La siguiente tabla describe los datos personales recopilados cuando se
          conecta por primera vez a los Servicios Sioslife:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de datos personales
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descripción de la categoría
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos del Usuario
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Estos son los datos personales ofrecidos por el usuario o
                recopilados por Sioslife para permitir que el usuario utilice
                los Servicios Sioslife. Dichos datos personales pueden incluir
                nombre de usuario, dirección de correo electrónico, número de
                teléfono, fecha de nacimiento, sexo, dirección y país.
              </Typography>
              <br />
              <Typography color="black" variant="smallBody">
                Algunos de los datos personales solicitados son necesarios para
                crear la cuenta de usuario. También tiene la opción de
                proporcionarnos datos personales adicionales para personalizar
                su cuenta.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          La siguiente tabla destaca los datos personales recopilados según la
          categoría de usuario:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de datos personales
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descripción de la categoría
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos de Utilización del &quot;Mayor&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Estos son los datos personales recopilados sobre usted cuando
                accede y / o utiliza los Servicios Sioslife:
              </Typography>
              <ul style={{ marginTop: "12px" }}>
                <li>
                  <Typography color="black" variant="smallBody">
                    Información sobre su suscripción de Servicios Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Información sobre sus interacciones con los Servicios
                    Sioslife, como pueden ser las preferencias, aplicaciones
                    usadas, contenidos personalizados para usted, contenidos que
                    se han compartido o que se han recibido;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conclusiones sobre sus intereres y preferencias con base en
                    su utilización de los Servicios Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Contenidos del Usuario (según se define en los [Términos y
                    Condiciones de Uso] (TC)) que publica en los Servicios
                    Sioslife, tales como fotografías o vídeos. No olvide que
                    solo accederemos a su cámara o a las fotografías de su
                    dispositivo si nos da permiso para ello, dado que solo
                    tendremos acceso a las imágenes que elija específicamente
                    compartir con nosotros, así como a los metadatos
                    relacionados con esas imágenes, tales como el tipo de
                    archivo y el tamaño de la imagen. En ningún caso accederemos
                    a ningún tipo de datos que no hayan sido compartidos a
                    través de los Servicios Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Algunos datos personales técnicos, que pueden incluir:
                  </Typography>
                  <ul>
                    <li>
                      <Typography color="black" variant="smallBody">
                        información de URL;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        identificadores online, incluyendo datos de cookies y
                        direcciones de IP;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        información sobre los tipos de dispositivos usados,
                        tales como IDs de dispositivos exclusivos, tipo de
                        conexión de red (por ejemplo, Wi-Fi, 3G, LTE,
                        Bluetooth), desempeño del proveedor, la red y el
                        dispositivo, tipo de navegador, idioma, informaciones
                        para la gestión de derechos digitales, sistema operativo
                        y versión de la aplicación Sioslife;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        atributos de dispositivos en su rede Wi-Fi disponibles
                        para conectarse a los Servicios Sioslife (como
                        altavoces);
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        su localización no precisa, que puede derivares o
                        inferirse partiendo de determinados datos técnicos (por
                        ejemplo, de su dirección de IP, de la configuración de
                        idioma en su dispositivo o de la moneda de pago), para
                        cumplir los requisitos geográficos de nuestros contratos
                        de licenciamiento y ofrecer contenido y publicidad
                        personalizados;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        datos de sensores móviles generados por movimiento u
                        orientación (por ejemplo, acelerómetro o giroscopio)
                        necesarios para ofrecerle características específicas de
                        los Servicios Sioslife;
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos de Utilización del &quot;Cuidador&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Estos son los datos personales recopilados sobre usted cuando
                accede y / o utiliza los Servicios Sioslife:
              </Typography>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Información sobre su suscripción de Servicios Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Conclusiones sobre sus intereres y preferencias con base en
                    su utilización de los Servicios Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Contenidos del Usuario (según se define en los [Términos y
                    Condiciones de Uso] (TC)) que publica en los Servicios
                    Sioslife, tales como fotografías o vídeos. No olvide que
                    solo accederemos a su cámara o a las fotografías de su
                    dispositivo si nos da permiso para ello, dado que solo
                    tendremos acceso a las imágenes que elija específicamente
                    compartir con nosotros, así como a los metadatos
                    relacionados con esas imágenes, tales como el tipo de
                    archivo y el tamaño de la imagen. En ningún caso accederemos
                    a ningún tipo de datos que no hayan sido compartidos a
                    través de los Servicios Sioslife;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Algunos datos personales técnicos, que pueden incluir:
                  </Typography>
                  <ul>
                    <li>
                      <Typography color="black" variant="smallBody">
                        información de URL;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        identificadores online, incluyendo datos de cookies y
                        direcciones de IP;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        información sobre los tipos de dispositivos usados,
                        tales como IDs de dispositivos exclusivos, tipo de
                        conexión de red (por ejemplo, Wi-Fi, 3G, LTE,
                        Bluetooth), desempeño del proveedor, la red y el
                        dispositivo, tipo de navegador, idioma, informaciones
                        para la gestión de derechos digitales, sistema operativo
                        y versión de la aplicación Sioslife;
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        atributos de dispositivos en su rede Wi-Fi disponibles
                        para conectarse a los Servicios Sioslife (como
                        altavoces);
                      </Typography>
                    </li>
                    <li>
                      <Typography color="black" variant="smallBody">
                        su localización no precisa, que puede derivares o
                        inferirse partiendo de determinados datos técnicos (por
                        ejemplo, de su dirección de IP, de la configuración de
                        idioma en su dispositivo o de la moneda de pago), para
                        cumplir los requisitos geográficos de nuestros contratos
                        de licenciamiento y ofrecer contenido y publicidad
                        personalizados;
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos de Verificación del Plan
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Podremos usar una aplicación de mapeado de terceros (como puede
                ser Google Maps) y / o el servicio de localización del
                dispositivo del usuario, para ayudarle a comprobar su respectiva
                dirección. Los referidos datos se recopilan con la única
                finalidad de verificación de elegibilidad para usar nuestro plan
                y no serán usados para fines publicitarios o cualquier otra
                finalidad.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          La tabla a continuación describe los datos personales que decide
          ofrecernos y que nos permiten ofrecer recursos / funcionalidades de
          nuestros servicios, independientemente del tipo de usuario:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de datos personales
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descripción de la categoría
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos de Voz
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sioslife recopila los datos de voz del usuario después de
                obtener la autorización de este último con el fin de brindarle
                recursos y funcionalidades adicionales, tales como la
                interacción con los Servicios de Sioslife a través de la voz
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos de Vídeo
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sioslife recopila los datos de vídeo del usuario después de
                obtener la autorización de este último con el fin de brindarle
                recursos y funcionalidades adicionales, tales como la
                interacción con los Servicios de Sioslife a través de la voz.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos de Pago y Compra
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Sioslife puede recopilar ciertos datos personales en caso de que
                se suscriba a un Periodo de Prueba o cualquiera de nuestras
                Suscripciones de Pago (como se define en los Términos y
                condiciones de uso) o realizar otras compras a través de los
                Servicios de Sioslife. Los datos personales exactos recopilados
                variarán según el método de pago (por ejemplo, directamente a
                través del operador de telefonía móvil del usuario o mediante
                una factura), pero incluyen información como:
              </Typography>
              <ul style={{ marginTop: "12px" }}>
                <li>
                  <Typography color="black" variant="smallBody">
                    Nombre;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Fecha de nacimiento;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Tipo de tarjeta de crédito o débito, fecha de caducidad y
                    determinados del número de tarjeta del usuario;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Código postal;
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Número de móvil; y
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Detalles del historial de compras y pagos del usuario
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Datos Relativos a Concursos, Encuestas y Sorteos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Siempre que rellene un formulario, responda a una encuesta o
                cuestionario o participe en un concurso, procederemos a recoger
                los datos personales facilitados.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          La tabla a continuación describe los datos personales recogidos a
          partir de terceros. Recopilamos los datos personales de los usuarios a
          partir de terceros. Estas fuentes (terceros) varían a lo largo del
          tiempo e incluyen:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de las fuentes (terceros)
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descripción de la categoría
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Socios de autenticación
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                En el caso de que se registre o acceda a los Servicios Sioslife
                utilizando credenciales de terceros (como por ejemplo, Facebook
                o Google), procederemos a la importación de la información a
                partir de terceros para efectos de creación de su cuenta con
                nosotros.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Socios de servicios técnicos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Trabajamos con socios de servicios técnicos que nos facilitan
                determinados datos, tales como el mapeado de direcciones de IP
                en materia de datos de localización no precisa (como por
                ejemplo, ciudad, estado), que nos permiten ofrecer los Servicios
                Sioslife, contenido y funcionalidades.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Socios de pago
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Si elige pagar por un servicio o funcionalidad mediante factura,
                podemos recibir datos de nuestros socios de pago con el fin de
                enviarle facturas, procesar su pago y proporcionar productos.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Anunciantes y otros socios de publicidad
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Podremos obtener determinados datos del usuario, tales como el
                ID de las cookies, el ID del dispositivo móvil o la dirección
                electrónica, así como conclusiones sobre los intereses o
                preferencias de determinados anunciantes y socios de publicidad,
                lo que nos permite presentar anuncios más relevantes y evaluar
                su respectiva eficacia.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        5. ¿Para qué fines usamos sus datos personales?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Cuando utiliza o interactúa con los Servicios Sioslife, usamos una
          variedad de tecnologías para procesar los datos personales que
          recopilamos del usuario por varias razones. En la tabla a continuación
          se presentan los motivos por los que procesamos los datos personales
          de los usuarios, las bases legales en las que nos basamos para el
          procesamiento de los datos personales y las categorías de datos
          personales (identificadas en la Sección anterior) usadas para tales
          finalidades:
        </Typography>
      </div>
      <div className={legalStyles.description} style={{ overflowX: "auto" }}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Descripción de los motivos por los que procesamos los datos
                personales del usuario (&quot;finalidad del tratamiento&quot;)
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Base Legal para la finalidad del tratamiento
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de datos personales usadas por Sioslife para la
                finalidad del tratamiento
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Prestar y personalizar los Servicios Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Ejecución de un Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimiento
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Verificación del Plano
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Voz
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Entender, diagnosticar, solucionar y corregir problemas de los
                Servicios Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Ejecución de un Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Evaluar y desarrollar nuevas funcionalidades, tecnologías y
                mejoras para los Servicios Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimiento
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Voz
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Finalidades de marketing, promoción y publicidad
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimiento
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Voz
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Cumplir las obligaciones legales y los pedidos de aplicación de
                la ley
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Cumplimiento de Obligaciones Legales
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Voz
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos relativos a Concursos, Encuestas y Sorteos
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Cumplir obligaciones contractuales con terceros (por ejemplo,
                acuerdos de licenciamiento), así como adoptar medidas adecuadas
                en relación a informes de violación de la propiedad intelectual
                y de contenido inadecuado
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Declarar, ejercer o defender un derecho en un proceso judicial
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Voz
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos relativos a Concursos, Encuestas y Sorteos
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Llevar a cabo la planificación de negocios, informes y
                previsiones.
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Procesar su pago
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Cumplimiento de Obligaciones Legales
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Ejecución de un Contrato
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Detectar fraudes, incluyendo pagos y el uso fraudulento de los
                Servicios Sioslife
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Cumplimiento de Obligaciones Legales
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Ejecución de un Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Pago y Compra
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Llevar a cabo búsquedas, concursos, encuestas y sorteos
              </Typography>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Consentimiento
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Realización de un Contrato
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Interés Legítimo
                  </Typography>
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos del Usuario
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos de Utilización
                  </Typography>
                </li>
                <li>
                  <Typography color="black" variant="smallBody">
                    Datos relativos a Concursos, Encuestas y Sorteos
                  </Typography>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        6. ¿Cómo compartimos sus datos personales?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Información disponible para usuarios de las plataformas Sioslife
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Establecemos categorías de destinatarios de los dados personales
          recopilados o generados a través de su utilización de los Servicios
          Sioslife.
          <br />
          Los datos personales descritos a continuación estarán siempre
          disponibles en los Servicios Sioslife: su nombre y / o nombre de
          usuario, fotografía de perfil, círculo de contactos creados por el
          usuario y quien se ha registrado en los Servicios Sioslife y las
          informaciones públicas del usuario.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Datos personales que el usuario puede optar por compartir
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Los datos personales indicados a continuación se compartirán solamente
          con las categorías de destinatarios indicadas en la tabla siguiente
          si:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              El usuario opta por recurrir a una funcionalidad concreta de los
              Servicios Sioslife en la que sean necesarios los datos personales
              específicos compartidos para el uso adecuado de las
              funcionalidades de los Servicios Sioslife;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              El usuario concediese autorización para compartir sus datos
              personales, por ejemplo, seleccionando la configuración apropiada
              de los Servicios Sioslife o autorizando a Sioslife a través del
              mecanismo de consentimiento que esta ofrece (normalmente la
              declaración de consentimiento en la primera utilización de
              nuestros servicios);
            </Typography>
          </li>
        </ul>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de Destinatarios
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Justificación para Compartir Datos Personales
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Aplicaciones de terceros y dispositivos conectados al usuario
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                En el caso de que el usuario conecte su cuenta Sioslife a una
                aplicación y / o dispositivo(s) de terceros, tales como las
                redes sociales, audio, televisión o plataformas y servicios
                automóviles, Sioslife comparte datos técnicos con el operador de
                la aplicación en cuestión y / o dispositivo de terceros para
                conectar la cuenta Sioslife del usuario y / o facilitar la
                reproducción de los Servicios Sioslife. Relativamente a algunos
                terceros que puedan solicitar o exigir que compartamos la
                información del usuario con los mismos, su autorización se
                solicitará antes de que ofrezcamos cualquier información.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Compartir datos entre el &quot;mayor&quot; y el
                &quot;cuidador&quot;
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                En el caso de que alguno de los usuarios desee establecer
                contacto en tiempo real o compartir contenidos como mensajes
                escritos, voz, vídeos u otros elementos multimedia. En el caso
                de que alguno de los cuidadores desee personalizar o ayudar en
                las configuraciones remotas del dispositivo utilizado por el
                mayor.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black" weight="medium">
          Información que podemos compartir
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <table>
          <tr>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Categorías de Destinatarios
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody" weight="medium">
                Justificación para Compartir Datos Personales
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Prestadores de Servicios
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Colaboramos con prestadores de servicios que trabajan en nuestro
                nombre y que pueden necesitar acceder a determinados datos
                personales de los usuarios para ofrecer sus servicios. Las
                mencionadas empresas incluyen las empresas contratadas para
                prestar los servicios de apoyo al cliente, para operar la
                infraestructura técnica necesaria para ofrecer los Servicios
                Sioslife, para ayudar a proteger y garantizar nuestros sistemas
                y servicios, así como para prestar asistencia en la
                comercialización de los productos y servicios de Sioslife.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Procesadores de pagos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Compartimos sus datos personales con nuestros procesadores de
                pago según sea necesario, para permitir que estos procesen sus
                pagos así como para combatir el fraude.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Socios publicitarios
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Colaboramos con socios publicitarios, lo que nos permite
                personalizar el contenido de publicidad que el usuario pueda
                recibir en los Servicios Sioslife. Dichos socios nos ayudan a
                presentar los anuncios y los mensajes promocionales más
                relevantes para el usuario, desde publicidad basada en intereses
                (publicidad conductual en línea), hasta publicidad contextual y
                publicidad genérica en los Servicios Sioslife. Junto con
                nuestros socios publicitarios, podemos procesar ciertos datos
                personales para ayudar a comprender a Sioslife sus intereses o
                preferencias al presentarle anuncios más relevantes.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Investigadores Académicos
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Compartimos sus datos personales para actividades tales como el
                análisis estadístico y el estudio académico, pero solo bajo un
                seudónimo.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Otras Empresas del Grupo Sioslife
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Compartimos sus datos personales con otras empresas del Grupo
                Sioslife con la intención de realizar nuestras operaciones
                comerciales diarias y permitirnos mantener y ofrecer a los
                usuarios los Servicios Sioslife.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Autoridades de aplicación de la ley y de protección de datos
                personales
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Compartimos sus datos personales cuando, de buena fe, creemos
                que es necesario hacerlo con el fin de cumplir con obligación
                legal impuesta al amparo de la legislación vigente, o como
                respuesta a un proceso legal válido, como un mandato de
                búsqueda, una orden judicial o una citación. También compartimos
                sus datos personales cuando, de buena fe, creemos que es
                necesario con fines de nuestro propio interés legítimo o del
                interés legítimo de terceros en relación con la seguridad
                nacional, aplicación de la ley, litigios, investigación
                criminal, protección de la seguridad de cualquier persona o para
                prevenir la muerte o daños físicos inminentes, siempre que
                consideremos que dicho interés no se ve anulado por los
                intereses del usuario o por los derechos y libertades
                fundamentales que requieran la protección de sus datos
                personales.
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography color="black" variant="smallBody">
                Compradores de la actividad comercial de Sioslife
              </Typography>
            </td>
            <td>
              <Typography color="black" variant="smallBody">
                Compartimos sus datos personales en casos de venta o negociación
                de la venta de nuestra actividad comercial a un comprador o
                potencial comprador. En esta situación, Sioslife continúa
                garantizando la confidencialidad de los datos personales del
                usuario y le informará antes de la transmisión de los datos
                personales del usuario al comprador o de que estos queden
                sujetos a una política de privacidad diferente.
              </Typography>
            </td>
          </tr>
        </table>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife garantiza que solo se compartirán eventualmente los datos a
          entidades que demuestren seguir una política de privacidad idéntica.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        7. Conservación y eliminación de datos personales
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Procedemos a conservar sus datos personales solo durante el tiempo que
          sea necesario con el fin de proporcionarle los Servicios de Sioslife,
          así como para fines comerciales legítimos y esenciales, tales como el
          mantenimiento del rendimiento de los Servicios de Sioslife, la toma de
          decisiones comerciales basadas en datos de novedades y ofertas, el
          cumplimiento de nuestras obligaciones legales y la resolución de
          disputas.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Si así lo solicita, eliminaremos o anonimizaremos sus datos personales
          para que ya no podamos identificarlo, a menos que estemos legalmente
          autorizados u obligados a conservar ciertos datos personales, incluso
          en las siguientes situaciones:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <ul>
          <li>
            <Typography color="black">
              En el caso de que exista un problema sin resolver relacionado con
              su cuenta, como un crédito pendiente o una reclamación o litigio
              en curso, conservaremos los datos personales necesarios hasta que
              se resuelva el problema;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Cuando sea necesario conservar los datos personales para efectos
              de nuestras obligaciones legales, fiscales, de auditoría y
              contables, conservaremos los datos personales necesarios durante
              el período requerido por la ley aplicable;
            </Typography>
          </li>
          <li>
            <Typography color="black">
              Siempre que sea necesario para nuestros intereses comerciales
              legítimos, tales como prevenir el fraude o mantener la seguridad
              de nuestros usuarios.
            </Typography>
          </li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        8. Transmisión de datos personales a otros países
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife comparte sus datos personales de manera global con las
          empresas del Grupo Sioslife a efectos de realizar las actividades
          especificadas en la presente Política. Sioslife puede igualmente
          subcontratar el tratamiento, o compartir sus datos personales con
          terceros localizados en otros países. Los datos personales del usuario
          pueden, por lo tanto, encontrarse sujetos a leyes de privacidad
          diferentes de las aplicables en su país.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Los datos personales recopilados en la Unión Europea y en Suiza
          podrán, por ejemplo, transmitirse y procesarse por terceros
          localizados en un país fuera de la Unión Europea y de Suiza. En estos
          casos, Sioslife deberá garantizar que la transmisión de los datos
          personales del usuario se realice de acuerdo con las leyes de
          privacidad aplicables y, en particular, que se encuentren en vigor
          todas las medidas contractuales, técnicas y organizacionales adecuadas
          tales como las Cláusulas Contractuales Generales aprobadas por la
          Comisión Europea.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        9. Enlaces
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Podemos mostrar anuncios de terceros y otros contenidos que integren
          enlaces a sitios de terceros. No podemos, sin embargo, controlar o ser
          responsabilizados por las prácticas de privacidad y los contenidos de
          terceros. En el caso de hacer clic en un anuncio o enlace de terceros,
          debe saber que estará abandonando los Servicios Sioslife y cualquier
          dato personal que facilite no se encontrará protegido por la presente
          Política. Recomendamos que lea las respectivas políticas de privacidad
          para comprender la forma de recogida y tratamiento de sus datos
          personales por parte de estos terceros.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        10. Mantenimiento de la seguridad de sus datos personales
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Ponemos toda nuestra dedicación en proteger los datos personales de
          nuestros usuarios. Para ello implementamos medidas técnicas y
          organizacionales adecuadas para contribuir a la protección de la
          seguridad de los mencionados datos personales; sin embargo recordamos
          que ningún sistema es infalible. Instauramos varias políticas,
          incluidas políticas de seudonimización, criptografiado, acceso y
          conservación para proteger contra el acceso no autorizado y la
          conservación innecesaria de datos personales en nuestros sistemas.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Su contraseña protege su cuenta de usuario, por lo que le recomendamos
          que use una contraseña robusta y exclusiva para su cuenta Sioslife,
          nunca compartiéndola con nadie, limitando el acceso a sus dispositivos
          o navegadores, así como desconectando debidamente los Servicios
          Sioslife tras cada utilización.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        11. Menores
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Los Servicios Sioslife no están especialmente dirigidos a niños con
          menos de 13 años. Los Servicios Sioslife tampoco se ofrecen a niños
          cuyo procesamiento de datos personales sea ilegal debido a su edad, o
          requieran el consentimiento de los padres para el procesamiento de los
          datos personales al amparo del RGPD o de otra ley local.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          No recogemos conscientemente datos personales de niños menores de 13
          años o con una edad inferior al límite aplicable (el &quot;Límite de
          edad&quot;). Si la edad del usuario fuese inferior al Límite de Edad,
          no nos ofrezca ningún dato personal.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Su es el padre/madre de un menor de edad y sabe que su hijo ha
          ofrecido datos personales a Sioslife, por favor entre en contacto con
          nosotros a través del formulario &quot;Contáctenos&quot; disponible en
          nuestro sitio web, donde porá ejercer sus derechos tal como se indica
          con anterioridad en la presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          En el caso de tomar conocimiento de que estamos recogiendo datos
          personales de un niño con menos de 13 años de edad, tomaremos medidas
          razonables para eliminar los datos personales en cuestión, lo que
          podrá llevarnos a eliminar la respectiva cuenta Sioslife.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        12. Alteraciones a la presente Política
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Podremos ocasionalmente realizar alteraciones a la presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Cuando realicemos alteraciones materiales a la presente Política le
          enviaremos la respectiva notificación adecuadamente y de acuerdo con
          las circunstancias, por ejemplo mostrando un aviso visible en los
          Servicios Sioslife o mediante el envío de un email y / o una
          notificación al dispositivo del usuario. Le informaremos con
          antecedencia.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Asegúrese por ello de leer atentamente cualquier aviso de esta
          naturaleza.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Para informaciones adicionales sobre la presente Política y en
          relación a la forma mediante la cual Sioslife usa los datos personales
          del usuario, por favor entre en contacto con nosotros a través de
          cualquier canal disponible al efecto.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        13. ¿Cómo puede contactarnos?
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Gracias por leer nuestra Política. Si tiene alguna duda en relación a
          la presente Política, entre en contacto con nosotros a través del
          email{" "}
          <a href={launcher ? "#" : "mailto:compliance@sioslife.com"}>
            compliance@sioslife.com
          </a>{" "}
          o del formulario disponible en nuestro sitio web -{" "}
          <a href={launcher ? "#" : "https://www.sioslife.com"}>
            https://www.sioslife.com
          </a>
          . Puede también enviar una carta a la siguiente dirección:
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          <a
            href={launcher ? "#" : "https://g.page/sioslife?share"}
            rel="noreferrer"
            target={launcher ? "" : "_blank"}
          >
            Rua Eng. Nuno Alvares Pereira, No 33 Gualtar 4710-915, Braga
            (Portugal)
          </a>
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife es una marca registrada y gestionada por la entidad Sioslife
          S.A. Sioslife S.A. es la entidad responsable por el procesamiento de
          los datos personales gestionados al amparo de la presente Política.
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">
          ¡Esperamos que disfrute de nuestros servicios!
        </Typography>
      </div>
      <div className={legalStyles.description}>
        <Typography color="black">© Sioslife / Sioslife S.A.</Typography>
      </div>
    </LegalDocument>
  );
}

PrivacyPolicy.propTypes = {
  launcher: PropTypes.bool,
};

PrivacyPolicy.defaultProps = {
  launcher: false,
};

export default PrivacyPolicy;
